.ecosystem-section {
  margin: 72rem 0;
  padding: 0 40rem;

  @media (max-width: $screen-lg) {
    margin: 32px 0;
    padding: 0 20px;
  }

  .title-wrap {
    text-align: center;
    color: $white-color;
    margin-bottom: 18rem;

    @media (max-width: $screen-lg) {
      margin-bottom: 24px;
    }
  }

  .content-wrap {
    position: relative;

    .main-img-wrap {
      width: 100%;
      position: absolute;
      top: -50%;
      left: 0;

      @media (max-width: $screen-lg) {
        top: 0;
        margin-left: -20px;
        position: relative;
      }
    }

    img {
      width: 100%;

      @media (max-width: $screen-lg) {
        width: calc(100% + 40px);
      }
    }
  }
}
