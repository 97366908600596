footer {
  padding: 48rem 80rem;
  background-color: #14161B;
  position: relative;
  z-index: 2;

  @media (max-width: $screen-sm) {
    padding: 32px 20px;
  }

  .content-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $screen-lg) {
      flex-direction: column-reverse;
      gap: 20px;
    }

    .copyright-wrap p {
      color: $white-color;
    }

    .links-wrap {

      ul {
        display: flex;
        gap: 24rem;
        margin: 0;
        padding: 0;

        li a {
          color: $white-color;
          text-decoration: none;

          &:hover p {
            background: linear-gradient(180deg, #00D1DD 0%, #5DFF00 100%), #5E6369;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
      }
    }
  }
}
