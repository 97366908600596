.packages-section {
  margin: 60rem 0 72rem;
  padding: 0 40rem;

  @media (max-width: $screen-lg) {
    margin: 32px 0;
    padding: 0 20px;
  }

  .title-wrap {
    text-align: center;
    color: $white-color;
    margin-bottom: 56rem;

    @media (max-width: $screen-lg) {
      margin-bottom: 32px;
    }

    span {
      background: linear-gradient(180deg, #00D1DD 0%, #5DFF00 100%), #FFFFFF;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .gradient {
      background: linear-gradient(90deg, #ff2163 35%, #e4a801, #8fff01 85%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .items-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20rem;

    @media (max-width: $screen-lg) {
      flex-direction: column;
    }

    .item {
      display: flex;
      flex-direction: column;
      position: relative;
      border-radius: 24rem;
      padding: 24rem;
      overflow: hidden;
      color: $white-color;
      text-decoration: none;
      pointer-events: none;

      &:nth-child(1), &:nth-child(4) {
        width: 25%;

        @media (max-width: $screen-lg) {
          width: 100%;
        }
      }

      &:nth-child(2), &:nth-child(3) {
        width: calc(25% - 30rem);

        @media (max-width: $screen-lg) {
          width: 100%;
        }
      }

      &:hover {

        .subscribe-wrap .arrow-wrap {
          background-color: rgba($white-color, .1);
          transform: translateX(10%);
        }
      }

      &.item-color {

        &::before {
          background: linear-gradient(180deg, #FF0091 0%, #FF9300 100%), linear-gradient(180deg, #00D1DD 0%, #5DFF00 100%), #FFFFFF;
        }

        .text-wrap h5 {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .text {
            background: linear-gradient(180deg, #FF0091 0%, #FF9300 100%), linear-gradient(180deg, #00D1DD 0%, #5DFF00 100%), #FFFFFF;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }

          .badge {
            font: 500 12rem/.9 $headline-font;
            letter-spacing: calc(12rem * .03);
            padding: 8rem;
            border-radius: 6rem;
            background: linear-gradient(180deg, #FF0091 0%, #FF9300 100%), linear-gradient(360deg, #00D1DD 0%, #5DFF00 100%), #FFFFFF;
          }
        }
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background-color: rgba($white-color, .2);
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        height: calc(100% - 4rem);
        width: calc(100% - 4rem);
        top: 2rem;
        left: 2rem;
        z-index: 1;
        border-radius: 24rem;
        background: $card-gradient;
      }

      > * {
        position: relative;
        z-index: 2;
      }

      .text-wrap {

        .header {
          img {
            position: absolute;
            top: 0;
            right: 0;
            width: 128rem;
            height: 128rem;
            margin-right: 12rem;

            @media (max-width: $screen-lg) {
              width: 96px;
              height: 96px;
              margin-right: 8px;
            }

            @media (max-width: $screen-sm) {
              width: 64px;
              height: 64px;
            }
          }
        }

        .pricing {
          min-height: 80rem;

          @media (max-width: $screen-lg) {
            min-height: 0px;
          }
        }

        h5 {
          margin-bottom: 16rem;
        }

        h6 {
          margin-bottom: 12rem;
        }

        ul {
          margin: 0 0 12rem;
          padding: 0;
          list-style-type: none;

          li {
            display: flex;
            align-items: center;
            padding: 12rem 0;

            img {
              padding-right: 12rem;
            }

            @media (max-width: $screen-lg) {
              padding: 8px 0;
            }

            &:not(:last-child) {
              border-bottom: 1rem solid rgba(#D9D9D9, .2);
            }

            p {
              color: $text-color;
            }
          }
        }
      }

      .beta-access {
        display: flex;
        justify-content: space-between;

        .main {
          display: flex;
          flex-wrap: wrap !important;
          align-items: center;

          img {
            width: 30rem;
            height: 30rem;
          }

          p {
            font-weight: 500;
            font-size: 15rem;
          }
        } 

        img {
          width: 24rem;
          height: 24rem;
        }
      }

      .subscribe-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font: 500 13rem/1 $text-font;
          text-transform: uppercase;
          letter-spacing: 0;
        }

        .arrow-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 38rem;
          height: 38rem;
          border-radius: 12rem;
          transition: $fast-transition;
          border: 2rem solid rgba($white-color, .3);
        }
      }
    }
  }
}
