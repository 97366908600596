@use '../../vars/colors';
@use '../../vars/breakpoints';
@use '../../vars/typography';

.partnership-hero {
  position: relative;
  display: inline-flex;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../img/partnership/hero.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.2;
    z-index: -1;
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0));
    -webkit-mask-size: 100% 100%;
    -webkit-mask-repeat: no-repeat;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
  }

  .content {
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-top: 200px;
    padding-bottom: 0px;
    width: 100%;
    height: 100%;
    justify-content: space-between;

    .content-wrap {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      padding-left: 10%;
      padding-right: 10%;

      .title {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        word-wrap: break-word;

        .headline {
          align-self: stretch;
          color: $white-color;
          font-size: calc(16px + 5vw);
          font-family: $headline-font;
          font-weight: 500;
          line-height: 110%;
        }

        .tagline {
          align-self: stretch;
          color: rgba($white-color, 0.8);
          font-size: 24px;
          font-family: $text-font;
          font-weight: 400;
          line-height: 38px;
          padding-top: 32px;
        }
      }

      .btn-wrap {
        justify-content: flex-start;
        align-items: center;
        gap: 32px;
        display: flex;
        padding-top: 48px;

        .btn {
          width: 204px;
          height: 44px;
          padding: 8px 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 12px;
          overflow: hidden;
          font-family: $text-font;
          font-size: 16px;
          white-space: nowrap;
          cursor: pointer;

          &.integration {
            position: relative;
            background: linear-gradient(
              87deg,
              color(display-p3 1 0 0.502) -50.52%,
              color(display-p3 1 0.549 0) 45.32%,
              color(display-p3 0.5608 1 0) 143.1%
            );
            color: #030215;
            font-weight: 500;
            overflow: hidden;
            transition: all 0.3s ease;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: -100%;
              width: 100%;
              height: 100%;
              background: rgba(163, 243, 116, 0.297);
              transition: left 0.3s ease;
            }

            &:hover::before {
              left: 0;
              background: rgba(163, 243, 116, 0.297);
            }

            &:hover {
              transform: scale(1.02);
            }
          }

          &.investment {
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid rgba(255, 255, 255, 0.1);
            color: white;
            font-weight: 500;
            transition: all 0.3s ease;

            &:hover {
              transform: scale(1.02);
              background: rgba(255, 255, 255, 0.1);
            }
          }

          .text {
            text-align: center;
            z-index: 1;
          }
        }
      }
    }

    .app-image {
      width: 100%;
      height: 100%;
      max-width: 50%;
      position: relative;
      display: inline-flex;

      img {
        position: relative;
        width: 100%;
        bottom: -50px;
        min-width: 500px;
      }
    }
  }

  @media (max-width: $screen-xl) {
    .content {
      padding: 96px 48px;
      padding-top: 112px;
      padding-bottom: 10px;
      gap: 30px;

      .content-wrap {
        width: 100%;
        gap: 40px;

        .title {
          height: auto;

          .headline {
            font-size: 64px;
          }
        }
      }

      .app-image {
        img {
          width: 120%;
          height: 120%;
          bottom: 20px;
        }
      }
    }
  }

  @media (max-width: $screen-lg) {
    &::before {
      background-image: url('../../../img/partnership/hero-mobile.png');
      opacity: 0.2;
    }

    .content {
      padding-top: 112px;
      padding-bottom: 0px;
      gap: 40px;
      flex-direction: row;

      .app-image {
        width: 100%;

        img {
          width: 150%;
          bottom: 50px;
        }
      }
    }
  }

  @media (max-width: $screen-sm) {
    &::before {
      background-image: url('../../../img/partnership/hero-mobile.png');
      opacity: 0.2;
    }

    .content {
      padding: 48px 24px;
      padding-top: 112px;
      padding-bottom: 0px;
      gap: 40px;
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;

      .content-wrap {
        width: 100%;
        gap: 40px;

        .title {
          height: auto;

          .headline {
            font-size: 40px;
          }
        }

        .btn-wrap {
          width: 100%;
          flex-direction: column;
          a {
            width: 100%;
          }
          .btn {
            width: 100%;
          }
        }
      }

      .app-image {
        width: 100%;

        img {
          width: 150%;
          height: 100%;
          object-fit: cover;
          object-position: top left;
          bottom: 0px;
        }
      }
    }
  }
}
