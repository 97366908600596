.btn-main {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  padding: 8rem;
  position: relative;
  max-width: max-content;
  border-radius: 90rem;

  &:hover {

    &::before {
      opacity: .3;
    }

    .icon img {
      transform: translateX(10%);
    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 80rem;
  }

  &::before {
    z-index: 0;
    opacity: .2;
    transition: $fast-transition;
    background: linear-gradient(89.44deg, #00FF88 -1.68%, #00A4A2 170.49%), linear-gradient(84.77deg, #1F3CFF -14.19%, #3AA9FF 88.71%), linear-gradient(84.77deg, #00DB45 -14.19%, #BFFF3C 88.71%), #FFFFFF;
  }

  &::after {
    z-index: 1;
    background: rgba($white-color, .05);
    backdrop-filter: blur(10rem);
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 90rem;
    height: 67rem;
    z-index: 2;
    position: relative;
    background: linear-gradient(89.44deg, #00FF88 -1.68%, #00A4A2 170.49%), #FFFFFF;

    @media (max-width: $screen-lg) {
      height: 56px;
    }
  }

  .text {
    font: 600 18rem/1 $headline-font;
    letter-spacing: calc(-18rem * .02);
    padding: 20rem 60rem;
    color: #030215;

    @media (max-width: $screen-lg) {
      font-size: 16px;
      padding: 12px 36px;
    }
  }

  .icon {
    min-width: 67rem;
    max-width: 67rem;

    @media (max-width: $screen-lg) {
      max-width: 56px;
      min-width: 56px;
    }

    img {
      width: 25rem;
      transition: $fast-transition;

      @media (max-width: $screen-lg) {
        width: 20px;
      }
    }
  }
}
