@import './breakpoints';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

@font-face {
  font-family: 'Roobert';
  src: url('../../fonts/Roobert-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Roobert';
  src: url('../../fonts/Roobert-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Roobert';
  src: url('../../fonts/Roobert-SemiBold.otf') format('opentype');
  font-weight: 600;
}

$headline-font: 'Roobert', sans-serif;
$text-font: 'Inter', sans-serif;

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font: 500 88rem/0.9 $headline-font;
  letter-spacing: calc(-88rem * 0.03);

  @media (max-width: $screen-lg) {
    font-size: 64px;
    letter-spacing: calc(-64px * 0.03);
  }

  @media (max-width: $screen-sm) {
    font-size: 48px;
    letter-spacing: calc(-48px * 0.03);
  }
}

h2 {
  font: 500 64rem/0.9 $headline-font;
  letter-spacing: calc(-64rem * 0.03);

  @media (max-width: $screen-lg) {
    font-size: 48px;
    letter-spacing: calc(-48px * 0.03);
  }

  @media (max-width: $screen-sm) {
    font-size: 40px;
    letter-spacing: calc(-40px * 0.03);
  }
}

h3 {
  font: 400 48rem/0.9 $headline-font;
  letter-spacing: calc(-48rem * 0.03);

  @media (max-width: $screen-lg) {
    font-size: 36px;
    letter-spacing: calc(-40px * 0.03);
  }
}

h4 {
  font: 500 40rem/0.9 $headline-font;
  letter-spacing: calc(-40rem * 0.03);

  @media (max-width: $screen-lg) {
    font-size: 24px;
    letter-spacing: calc(-20px * 0.03);
  }
}

h5 {
  font: 500 32rem/0.9 $headline-font;
  letter-spacing: calc(-32rem * 0.03);

  @media (max-width: $screen-lg) {
    font-size: 24px;
    letter-spacing: calc(-20px * 0.03);
  }
}

h6 {
  font: 400 20rem/1.4 $text-font;
  letter-spacing: calc(-20rem * 0.03);

  @media (max-width: $screen-lg) {
    font-size: 18px;
    letter-spacing: calc(-16px * 0.03);
  }

  @media (max-width: $screen-sm) {
    font-size: 16px;
    letter-spacing: calc(-14px * 0.03);
  }
}

p {
  font: 400 16rem/1.4 $text-font;
  letter-spacing: calc(-16rem * 0.03);

  @media (max-width: $screen-lg) {
    font-size: 14px;
    letter-spacing: calc(-14px * 0.03);
  }
}
