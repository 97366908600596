.grid-section {
  display: flex;
  width: 100%;
  height: var(--app-height);
  top: 0;
  left: 0;
  padding: 0 40rem;
  justify-content: space-between;
  position: fixed;
  z-index: 1;

  @media (max-width: $screen-lg) {
    padding: 0 20px;
  }

  .item {
    display: block;
    height: 100%;
    width: 1rem;
    background-color: rgba(#D9D9D9, .1);

    &:nth-child(-n+2) {

      @media (max-width: $screen-lg) {
        display: none;
      }
    }
  }
}
