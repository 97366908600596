.features-section {
  display: flex;
  flex-direction: column;
  gap: 72rem;
  position: relative;
  padding: 0 40rem;
  margin: 64rem auto 60rem;

  @media (max-width: $screen-lg) {
    padding: 0 20px;
    margin: 32px auto;
    gap: 32px;
  }

  .title-wrap {
    text-align: center;
    color: $white-color;
  }

  .item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-radius: 24rem;

    @media (max-width: $screen-lg) {
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
    }

    &.item-revert {

      @media (max-width: $screen-lg) {
        flex-direction: column-reverse;
      }

      .text-wrap {
        padding-left: 50rem;

        @media (max-width: $screen-lg) {
          padding-left: 0;
        }
      }
    }

    > div {
      width: 50%;

      @media (max-width: $screen-lg) {
        width: 100%;
      }
    }

    .text-wrap {
      padding-right: 50rem;

      @media (max-width: $screen-lg) {
        padding-right: 0;
      }

      .icon-wrap {
        margin-bottom: 24rem;

        img {
          width: 32rem;
        }
      }

      h3 {
        color: $white-color;
        margin-bottom: 16rem;
      }

      h6 {
        color: $text-color;
        max-width: 560rem;
        line-height: 1.6;

        @media (max-width: $screen-lg) {
          max-width: 100%;
        }
      }
    }

    .img-wrap {
      border-radius: 24rem;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
      }

      img {
        width: 100%;
      }

      video {
        width: 100%;
        border-radius: 30rem;
        pointer-events: none;
      }
    }
  }
}
