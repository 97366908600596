.hero-section {
  display: flex;
  padding: 40rem;
  position: relative;
  flex-wrap: wrap;
  align-items: flex-end;
  min-height: 640rem;
  max-height: 810rem;
  height: 100vh;

  @media (max-width: $screen-lg) {
    height: max-content;
    min-height: max-content;
    flex-direction: column;
    flex-wrap: initial;
    padding: 100px 20px 20px;
  }

  &:not(.active) {

    .active-element {
      opacity: 0;
      transform: translateY(10vh);

      @media (max-width: $screen-lg) {
        opacity: 1;
        transform: none;
      }
    }
  }

  .active-element {
    transition: opacity .75s ease-in-out, transform .75s ease-in-out;
  }

  .bg-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;

      @media (max-width: $screen-lg) {
        height: 100%;
        object-fit: cover;
        opacity: .1;
      }
    }
  }

  .content-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;

    @media (max-width: $screen-lg) {
      width: 100%;
      margin-bottom: 24px;
    }

    .text-wrap {
      color: $white-color;
      margin-bottom: 32rem;

      @media (max-width: $screen-lg) {
        margin-bottom: 24px;
      }

      h1 {
        margin-bottom: 32rem;

        @media (max-width: $screen-lg) {
          margin-bottom: 24px;
          max-width: 400px;
        }
      }

      h6 {
        max-width: 430rem;
        opacity: .8;
      }
    }

    .btn-wrap {
      position: relative;
      max-width: max-content;
    }
  }

  .notification-wrap {
    width: 25%;
    padding: 0 16rem;

    @media (max-width: $screen-lg) {
      width: 100%;
      padding: 0;
    }

    &.notification-1 {
      padding-bottom: 80rem;

      @media (max-width: $screen-lg) {
        padding-bottom: 0;
        margin-bottom: 24px;
        transform: translateX(20%);
      }
    }

    &.notification-2 {
      padding-bottom: 320rem;

      @media (max-width: $screen-lg) {
        padding-bottom: 0;
      }
    }

    .img-wrap {
      width: 100%;
      backdrop-filter: blur(10rem);
      background-color: rgba($white-color, .2);
      border-radius: 8rem;

      @media (max-width: $screen-lg) {
        max-width: 360px;
        width: 75%;
      }
    }

    img {
      width: 100%;
    }
  }

  .scroll-wrap {
    width: 62rem;
    height: 62rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 40rem;
    bottom: 40rem;
    border-radius: 50%;
    border: 2rem solid rgba($white-color, .4);

    @media (max-width: $screen-lg) {
      height: 56px;
      width: 56px;
      right: 20px;
      bottom: 20px;
    }
  }
}
