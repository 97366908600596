@use '../../vars/typography';

.investment-partnership {
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 130%;
    background-image: url('../../../img/partnership-investment/hero.png');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    opacity: 0.3;
    z-index: -1;
  }

  width: 100%;
  height: 100%;
  padding: 200px 128px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  position: relative;

  /* Background Blurred Circles */
  .background-blur {
    position: absolute;
    width: 1557px;
    height: 759px;
    z-index: -1;

    .circle {
      position: absolute;
      width: 593px;
      height: 594px;
      border-radius: 9999px;
      box-shadow: 300px 300px 300px;
      filter: blur(300px);

      &.purple {
        top: 20px;
        left: 0;
        background: #b64eff;
      }

      &.blue {
        top: 0;
        left: 396px;
        background: #34c3e9;
      }

      &.green {
        top: 165px;
        left: 964px;
        background: #2ef595;
      }
    }
  }

  /* Breadcrumb Navigation */
  .breadcrumb {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    z-index: 2;

    .breadcrumb-item {
      font-size: 16px;
      font-family: $text-font;
      font-weight: 400;
      word-wrap: break-word;
      color: rgba(255, 255, 255, 0.5);

      &.active {
        color: white;
      }
    }

    .breadcrumb-arrow {
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(0deg);

      &:before {
        content: '';
        width: 10.33px;
        height: 5.67px;
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }

  /* Title Section */
  .title-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title-main {
      width: 476px;
      text-align: center;
      font-size: 80px;
      font-family: 'Roobert', sans-serif;
      font-weight: 500;
      line-height: 110%;
      color: white;
      word-wrap: break-word;
    }

    .title-sub {
      text-align: center;
      font-size: 128px;
      font-family: 'Roobert', sans-serif;
      font-weight: 600;
      line-height: 100%;
      color: white;
      word-wrap: break-word;
    }
  }

  /* Apply Button */
  .apply-button {
    width: 168px;
    height: 44px;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        263deg,
        color(display-p3 0 0.998 0.5788) 0%,
        color(display-p3 0.0501 0.8457 0.934) 50%,
        color(display-p3 0.2547 0.3168 1) 100%
      ),
      color(display-p3 1 1 1);
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: rgba(163, 243, 116, 0.297);
      transition: left 0.3s ease;
    }

    &:hover::before {
      left: 0;
      background: rgba(163, 243, 116, 0.297);
    }

    &:hover {
      transform: scale(1.02);
    }

    a {
      z-index: 2;
    }

    .button-text {
      text-align: center;
      color: #030215;
      font-size: 16px;
      font-family: $text-font;
      font-weight: 500;
      word-wrap: break-word;
    }
  }
}

.about-model {
  width: 100%;
  height: 100%;
  padding: 96px 128px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .card {
    max-width: 600px;
    align-self: stretch;
    padding: 40px;
    border-radius: 20px;
    border: 2px rgba(255, 255, 255, 0.3) solid;
    backdrop-filter: blur(80px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
    background: linear-gradient(334deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.1) 100%);

    .card-title {
      align-self: stretch;
      color: white;
      font-size: 48px;
      font-family: 'Roobert', sans-serif;
      font-weight: 600;
      line-height: 100%;
      word-wrap: break-word;
    }

    .card-content {
      align-self: stretch;
      color: white;
      font-size: 16px;
      font-family: $text-font;
      font-weight: 400;
      line-height: 24px;
      word-wrap: break-word;

      p {
        font-family: $text-font;
        font-size: 16px;
        margin: 8px 0;
      }

      ol {
        margin: 0;
        padding-left: 20px;

        li {
          margin-bottom: 8px;
          word-wrap: break-word;
        }
      }

      a {
        color: white;
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .investment-partnership {
    &::before {
      background-image: url('../../../img/partnership-investment/hero-mobile.png');
    }

    padding: 120px 64px 64px;
    padding-inline: 24px;

    .background-circles {
      height: 1309px;
    }

    .breadcrumb {
      .breadcrumb-item {
        font-size: 12px;
      }
    }

    .title-section {
      .title-main {
        font-size: 28px;
      }

      .title-sub {
        font-size: 48px;
      }
    }
  }

  .about-model {
    padding: 48px 16px;

    .content-wrapper {
      width: 100%;
      gap: 16px;

      .card {
        padding: 24px;

        .card-title {
          font-size: 24px;
        }
      }
    }
  }
}
