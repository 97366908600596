$black-color: #000000;
$white-color: #FFFFFF;
$text-color: #DDDDDD;
$active-hover-color: #7279ED;

$headline-gradient: linear-gradient(104.78deg, #ECEDFF 30.84%, #7279ED 72.01%, #4D54CF 128.42%);
$text-gradient: linear-gradient(117.33deg, #ECEDFF -16.12%, #7279ED 67.63%, #4D54CF 182.41%);
$btn-gradient: linear-gradient(157.27deg, #ECEDFF -1.44%, #7279ED 72.06%, #4D54CF 172.78%);
$card-gradient: linear-gradient(333.86deg, #1A1C23 49.77%, #282A31 102.92%);

.headline-gradient {
  max-width: max-content;
  background: $headline-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-gradient {
  max-width: max-content;
  background: $text-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

$main-green-color: #10B981;
$main-red-color: #EF4444;
$main-yellow-color: #F59E0B;
$main-blue-color: #6691F5;
