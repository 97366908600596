.roadmap-section {
  margin: 72rem 0 0;
  padding: 0 40rem;

  @media (max-width: $screen-lg) {
    margin: 32px 0;
    padding: 0 20px;
  }

  .title-wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 56rem;
    gap: 24px;
    color: $white-color;

    @media (max-width: $screen-lg) {
      margin-bottom: 32px;
      align-items: flex-start;
    }

    h6 {
      opacity: .8;

      @media (max-width: $screen-lg) {
        width: 100%;
      }
    }
  }

  .items-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20rem;

    @media (max-width: $screen-lg) {
      flex-direction: column;
    }

    .item {
      display: flex;
      flex-direction: column;
      position: relative;
      border-radius: 24rem;
      padding: 24rem;
      gap: 24rem;
      overflow: hidden;
      color: $white-color;
      text-decoration: none;
      background: $card-gradient;
      border: 2rem solid rgba($white-color, .2);

      &:nth-child(1), &:nth-child(4) {
        width: 25%;

        @media (max-width: $screen-lg) {
          width: 100%;
        }
      }

      &:nth-child(2), &:nth-child(3) {
        width: calc(25% - 30rem);

        @media (max-width: $screen-lg) {
          width: 100%;
        }
      }

      .icon-wrap img {
        width: 32rem;
      }

      .headline-wrap {
        display: flex;
        flex-direction: column;
        gap: 16rem;

        p {
          color: $text-color;
          min-height: 46rem;

          @media (max-width: $screen-lg) {
            min-height: max-content;
          }
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 12rem;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          display: flex;
          align-items: center;
          gap: 8rem;

          &::before {
            content: '';
            display: block;
            width: 24rem;
            height: 24rem;
            background: url('../../../assets/img/roadmap/roadmap-coming-icon.svg') center no-repeat;
            background-size: cover;
          }

          &.done::before {
            background: url('../../../assets/img/roadmap/roadmap-done-icon.svg') center no-repeat;
            background-size: cover;
          }

          p {
            color: $text-color;
          }
        }
      }
    }
  }
}
