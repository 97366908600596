@import "./hero";
@import "./grow";
@import "./integration";
@import "./investment";

.partnership-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

a {
    text-decoration: none;
    color: inherit;
}