.info-page-section {
  padding: 165rem 40rem 96rem;
  position: relative;

  @media (max-width: $screen-lg) {
    padding: 120px 20px 60px;
  }

  .bg-wrap {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;

    @media (max-width: $screen-lg) {
      width: 200%;
    }

    img {
      width: 100%;
    }
  }

  .headline-wrap {
    display: flex;
    flex-direction: column;
    gap: 40rem;
    max-width: 785rem;
    margin-bottom: 88rem;
    color: $white-color;

    @media (max-width: $screen-lg) {
      gap: 24px;
      margin-bottom: 48px;
    }

    h6 {
      opacity: .8;

      a {
        color: $white-color;
      }
    }
  }

  .text-wrap {
    display: flex;
    flex-direction: column;
    gap: 48rem;
    max-width: 785rem;

    @media (max-width: $screen-lg) {
      gap: 32px;
    }

    a {
      color: $white-color;
    }

    p {
      opacity: .8;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li p::before {
        content: '— '
      }
    }

    .item-headline {
      color: $white-color;

      h4 + p {
        margin-top: 24rem;

        @media (max-width: $screen-lg) {
          margin-top: 16px;
        }
      }
    }

    .item {
      color: $white-color;

      h5 {
        margin-bottom: 24rem;

        @media (max-width: $screen-lg) {
          margin-bottom: 16px;
        }
      }
    }
  }
}
