@use '../../vars/typography';

.partnership-integration {
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../img/partnership-integration/hero.png');
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    opacity: 0.2;
    z-index: 1;
  }

  .integration-section {
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 200px 128px 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    position: relative;

    .background-circles {
      width: 1557px;
      height: 759px;
      position: absolute;

      .circle {
        width: 593px;
        height: 594px;
        position: absolute;
        border-radius: 9999px;
        filter: blur(250px);
        box-shadow: 500px 500px 500px;

        &.circle-green {
          background: #8fff01;
          left: 0;
          top: 20px;
        }

        &.circle-yellow {
          background: #ffe500;
          left: 396px;
          top: 0;
        }

        &.circle-pink {
          background: #ff4289;
          left: 964px;
          top: 165px;
        }
      }
    }

    .breadcrumb {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 4;

      .breadcrumb-item {
        padding: 0 8px;
        font-size: 16px;
        font-family: $text-font;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        cursor: pointer;

        &.active {
          color: white;
        }
      }

      .breadcrumb-separator {
        width: 16px;
        height: 16px;
        padding: 2.83px;
        transform: rotate(0deg);
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
          content: '';
          width: 10.33px;
          height: 5.67px;
          background: rgba(255, 255, 255, 0.5);
        }
      }
    }

    .title-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 2;

      .title-main {
        width: 476px;
        text-align: center;
        color: white;
        font-size: 80px;
        font-family: 'Roobert', sans-serif;
        font-weight: 500;
        line-height: 110%;
      }

      .title-sub {
        text-align: center;
        color: white;
        font-size: 128px;
        font-family: 'Roobert', sans-serif;
        font-weight: 600;
        line-height: 100%;
      }
    }

    .apply-button {
      position: relative;
      width: 168px;
      height: 44px;
      padding: 8px 16px;
      background: linear-gradient(
          87deg,
          color(display-p3 1 0 0.502) -50.52%,
          color(display-p3 1 0.549 0) 45.32%,
          color(display-p3 0.5608 1 0) 143.1%
        ),
        linear-gradient(91deg, color(display-p3 0 0.998 0.5788) 0%, color(display-p3 0.0859 0.6297 0.6297) 100%),
        color(display-p3 1 1 1);
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      cursor: pointer;
      overflow: hidden;
      transition: all 0.3s ease;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: rgba(163, 243, 116, 0.297);
        transition: left 0.3s ease;
        z-index: 1;
      }

      &:hover::before {
        left: 0;
        background: rgba(163, 243, 116, 0.297);
      }

      &:hover {
        transform: scale(1.02);
      }

      a {
        z-index: 2;
      }

      .button-text {
        text-align: center;
        color: #030215;
        font-size: 16px;
        font-family: $text-font;
        font-weight: 500;
      }
    }
  }

  .partnership-details {
    width: 100%;
    height: 100%;
    padding: 96px 128px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    .details-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;

      .card {
        align-self: stretch;
        padding: 40px;
        background: linear-gradient(334deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.1) 100%);
        border-radius: 20px;
        border: 2px rgba(255, 255, 255, 0.3) solid;
        backdrop-filter: blur(80px);
        display: flex;
        flex-direction: column;
        gap: 40px;
        max-width: 600px;

        .card-title {
          color: white;
          font-size: 48px;
          font-family: 'Roobert', sans-serif;
          font-weight: 600;
          line-height: 110%;
        }

        .card-content {
          color: white;
          font-size: 16px;
          font-family: $text-font;
          font-weight: 400;
          line-height: 150%;

          p {
            font-family: $text-font;
            font-size: 16px;
            margin: 8px 0;
            line-height: 150%;
          }

          ol {
            margin: 0;
            padding-left: 20px;

            li {
              margin-bottom: 8px;
              word-wrap: break-word;
            }
          }

          a {
            color: white;
            text-decoration: underline;

            &:hover {
              color: rgba(255, 255, 255, 0.8);
            }
          }
        }
      }
    }
  }

  @media (max-width: $screen-sm) {
    &::before {
      background-image: url('../../../img/partnership-integration/hero-mobile.png');
    }

    .integration-section {
      padding: 120px 64px 64px;
      padding-inline: 24px;

      .background-circles {
        height: 1309px;
      }

      .breadcrumb {
        .breadcrumb-item {
          font-size: 12px;
        }
      }

      .title-section {
        .title-main {
          font-size: 28px;
        }

        .title-sub {
          font-size: 48px;
        }
      }
    }

    .partnership-details {
      padding: 48px 16px;

      .details-container {
        width: 100%;
        gap: 16px;

        .card {
          padding: 24px;
          gap: 24px;

          .card-title {
            font-size: 24px;
          }

          .card-content {
            word-wrap: break-word;
          }
        }
      }
    }
  }
}
