.strategies-section {
  margin: 48rem 0 64rem;
  padding: 0 40rem;
  z-index: 1;

  @media (max-width: $screen-lg) {
    margin: 32px 0;
    padding: 0 20px;
  }

  .bg-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      position: absolute;
      bottom: 30%;
      left: 0;

      @media (max-width: $screen-lg) {
        height: 100%;
        object-fit: cover;
        opacity: .1;
      }
    }
  }

  .title-wrap {
    text-align: center;
    color: $white-color;

    @media (max-width: $screen-lg) {
      margin-bottom: 32px;
    }
  }

  .items-wrap {
    display: flex;
    gap: 20rem;
    margin-top: 72rem;
    transition: .75s all ease-in-out;

    @media (max-width: $screen-lg) {
      margin-top: 0;
    }

    @media (max-width: $screen-md) {
      flex-direction: column;
    }

    &:not(.animation-active) {

      .item {

        &.item-1 {
          z-index: 1;

          @media (min-width: $screen-lg) {
            transform: rotate3d(0.4, 1, 0, 25deg) translateY(10%);
          }
        }

        &.item-2 {
          z-index: 2;

          @media (min-width: $screen-lg) {
            transform: scale(1.2);
            transform-origin: top;
          }
        }

        &.item-3 {
          z-index: 1;

          @media (min-width: $screen-lg) {
            transform: rotate3d(-0.4, 1, 0, 25deg) translateY(10%);
          }
        }

        .description-wrap {
          opacity: 0;

          @media (max-width: $screen-lg) {
            opacity: 1;
          }
        }
      }
    }

    .item {
      flex: 1;
      position: relative;
      transition: .75s all ease-in-out;

      @media (max-width: $screen-lg) {
        min-height: 450px;
      }

      @media (max-width: $screen-md) {
        min-height: max-content;
      }

      &.item-2 {
        z-index: 2;
      }

      &:hover {

        .info-wrap {
          opacity: 1;
        }
      }

      .img-wrap {
        width: 100%;
        border-radius: 24rem;
        overflow: hidden;
        border: 4rem solid rgba($white-color, .2);

        @media (max-width: $screen-lg) {
          border-radius: 20px;
          height: 100%;
        }

        img {
          width: 100%;

          @media (max-width: $screen-lg) {
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .description-wrap {
        color: $white-color;
        position: absolute;
        left: 32rem;
        bottom: 32rem;
        width: calc(100% - 100rem);
        transition: .75s all ease-in-out;

        @media (max-width: $screen-lg) {
          left: 24px;
          bottom: 24px;
        }

        h4 {
          margin-bottom: 16rem;
        }

        p {
          opacity: .5;
          max-width: 320rem;
        }

        //.icon-wrap {
        //  display: flex;
        //  align-items: center;
        //  justify-content: center;
        //  width: 56rem;
        //  height: 56rem;
        //  position: absolute;
        //  bottom: 0;
        //  z-index: 2;
        //  right: -48rem;
        //
        //  @media (max-width: $screen-lg) {
        //    right: -60px;
        //  }
        //
        //  &::before, &::after {
        //    content: '';
        //    position: absolute;
        //    top: 0;
        //    left: 0;
        //    width: 100%;
        //    height: 100%;
        //    display: block;
        //    border-radius: 40rem;
        //  }
        //
        //  &::before {
        //    z-index: 0;
        //    opacity: .2;
        //    transition: $fast-transition;
        //    background: linear-gradient(89.44deg, #00FF88 -1.68%, #00A4A2 170.49%), linear-gradient(84.77deg, #1F3CFF -14.19%, #3AA9FF 88.71%), linear-gradient(84.77deg, #00DB45 -14.19%, #BFFF3C 88.71%), #FFFFFF;
        //  }
        //
        //  &::after {
        //    z-index: 1;
        //    background: rgba($white-color, .05);
        //    backdrop-filter: blur(10rem);
        //  }
        //
        //  span {
        //    display: flex;
        //    align-items: center;
        //    justify-content: center;
        //    height: 38rem;
        //    width: 38rem;
        //    z-index: 2;
        //    border-radius: 20rem;
        //    background: linear-gradient(89.44deg, #00FF88 -1.68%, #00A4A2 170.49%), linear-gradient(84.77deg, #1F3CFF -14.19%, #3AA9FF 88.71%), linear-gradient(84.77deg, #00DB45 -14.19%, #BFFF3C 88.71%), #FFFFFF;
        //  }
        //
        //  img {
        //    width: 16rem;
        //    position: relative;
        //    z-index: 2;
        //  }
        //}
      }

      .info-wrap {
        color: $white-color;
        height: 100%;
        border-radius: 24rem;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 32rem;
        background: $card-gradient;
        transition: $fast-transition;

        @media (max-width: $screen-lg) {
          padding: 24px;
          border-radius: 20px;
        }

        h4 {
          margin-bottom: 32rem;

          @media (max-width: $screen-lg) {
            margin-bottom: 24px;
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 16rem;
          margin: 0;
          padding: 0;
          list-style-type: none;
        }
      }
    }
  }

  .btn-wrap {
    display: flex;
    justify-content: center;
    margin: 56rem auto 0;

    @media (max-width: $screen-lg) {
      margin: 32px auto 0;
    }
  }
}
