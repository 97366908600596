@use '../../vars/breakpoints';

.investment-partners-section {
  width: 100%;
  padding: 96px 128px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 96px;
  max-width: 1440px;
  margin: 0 auto;

  .section-title {
    text-align: left;
    color: white;
    font-size: 64px;
    font-family: 'Roobert', sans-serif;
    font-weight: 500;
    line-height: 110%;
    word-wrap: break-word;
    width: 100%;
  }

  .cards-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;

    .card {
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 26px 56px;
      background: linear-gradient(
          334deg,
          color(display-p3 0.102 0.1098 0.1333) 49.77%,
          color(display-p3 0.1569 0.1647 0.1882) 102.92%
        ),
        color(display-p3 0.1624 0.1724 0.2362);
      border: 1px solid rgba(255, 255, 255, 0.3);
      overflow: hidden;
      height: 130px;

      img {
        width: 166px;
        height: 78px;
        object-fit: contain;
      }
    }
  }

  .show-more-btn {
    display: block;
    font-size: 16px;
    padding: 8px 16px;
    background: color(display-p3 1 1 1 / 0.05);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 12px;
    color: #fff;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  @media (max-width: $screen-xl) {
    padding: 48px 24px;
  }

  @media (max-width: $screen-sm) {
    padding: 48px 24px;
    gap: 40px;

    .section-title {
      font-size: 40px;
    }

    .show-more-btn {
      width: 100%;
      height: 44px;
    }
  }
}
