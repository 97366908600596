@use '../../vars/typography';

.partners-section {
  width: 100%;
  gap: 96px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 96px 128px;
  max-width: 1440px;
  margin: 0 auto;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../img/partnership/partners-background-img.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.2;
    z-index: -1;
  }

  .section-title {
    color: white;
    font-size: 64px;
    font-family: $headline-font;
    font-weight: 500;
    line-height: 110%;
    word-wrap: break-word;
    margin-bottom: 24px;
  }

  .cards-container {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;

    .card {
      padding: 32px;
      border-radius: 20px;
      background: linear-gradient(334deg, rgba(255, 255, 255, 0.03) 49.77%, rgba(255, 255, 255, 0.1) 102.92%);
      border: 2px rgba(255, 255, 255, 0.3) solid;
      backdrop-filter: blur(80px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      min-height: 350px;

      .card-main-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;

        .logo {
          width: 166px;
          height: 78px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .card-content {
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 100%;

          .title {
            font-size: 24px;
            font-family: $text-font;
            font-weight: 500;
            color: white;
          }

          .description {
            font-size: 16px;
            font-family: $text-font;
            font-weight: 400;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.9);
          }
        }
      }

      .actions {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-top: 24px;

        .icon-button {
          width: 40px;
          height: 40px;
          padding: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .icon {
            width: 24px;
            height: 24px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .show-more-btn {
    display: block;
    font-size: 16px;
    padding: 8px 16px;
    background: color(display-p3 1 1 1 / 0.05);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 12px;
    color: #fff;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  @media (max-width: $screen-xl) {
    padding: 48px 24px;
  }

  @media (max-width: $screen-sm) {
    padding: 48px 24px;
    gap: 24px;

    &::before {
      top: -80px;
      background-position: top;
      background-size: contain;
      background-image: url('../../../img/partnership/partners-background-img-mobile.png');
    }

    .cards-container {
      gap: 16px;

      .card {
        gap: 16px;
      }
    }

    .section-title {
      font-size: 40px;
    }

    .show-more-btn {
      width: 100%;
      height: 44px;
    }
  }
}
