@use '../../vars/typography';

.partnership-grow {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 64px;
  display: inline-flex;
  position: relative;
  margin: 0 auto;
  padding-top: 96px;
  padding-bottom: 64px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../img/partnership/bg-grow-project.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.2;
    z-index: -1;
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0));
    -webkit-mask-size: 100% 100%;
    -webkit-mask-repeat: no-repeat;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0));
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 48px;
    width: 50%;

    @media (max-width: $screen-md) {
      width: 100%;
      align-items: flex-start;
    }

    .title {
      color: white;
      font-size: calc(24px + (82 - 24) * ((100vw - 320px) / (1920 - 320)));
      font-family: $headline-font;
      font-weight: 500;
      line-height: 110%;
      word-wrap: break-word;
      letter-spacing: -1.92px;
      text-align: left;

      @media (max-width: $screen-xxl) {
        font-size: calc(24px + (62 - 24) * ((100vw - 320px) / (1920 - 320)));
      }

      .highlight {
        color: transparent;
        background: linear-gradient(
            87deg,
            color(display-p3 1 0 0.502) -50.52%,
            color(display-p3 1 0.549 0) 45.32%,
            color(display-p3 0.5608 1 0) 143.1%
          ),
          linear-gradient(91deg, color(display-p3 0 0.998 0.5788) 0%, color(display-p3 0.0859 0.6297 0.6297) 100%),
          color(display-p3 1 1 1);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .steps {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 96px;
    width: 50%;

    @media (max-width: $screen-md) {
      width: 80%;
      align-items: flex-start;
    }

    .step {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 64px;
      width: 100%;

      .step-number {
        font-size: 120px;
        font-family: $headline-font;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -2.88px;
        color: transparent;
        -webkit-text-stroke: 1px rgba(255, 255, 255, 0.3);
        -webkit-text-fill-color: transparent;
        width: 56px;
        text-align: center;
      }

      .step-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 70%;

        .title {
          font-size: 56px;
          color: white;
          font-family: $headline-font;
          font-weight: 500;

          @media (max-width: $screen-xxl) {
            font-size: 38px;
          }
        }

        .description {
          font-size: 24px;
          color: rgba(255, 255, 255, 0.8);
          font-family: $text-font;
          font-weight: 400;
          line-height: 24px;

          @media (max-width: $screen-xxl) {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (max-width: $screen-xl) {
    padding: 48px 24px;

    .bg-wrap {
      top: 0;
      left: 0;
      opacity: 0.1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content {
      .title {
        font-size: 48px;
      }
    }
  }

  @media (max-width: $screen-lg) {
    gap: 40px;
    flex-direction: column;
    align-items: center;

    &::before {
      background-size: contain;
      background-image: url('../../../img/partnership/bg-grow-project-mobile.png');
    }
  }

  @media (max-width: $screen-sm) {
    padding: 48px 24px;
    flex-direction: column;

    .content {
      .title {
        font-size: 40px;
      }
    }

    .steps {
      gap: 32px;
      .step {
        gap: 24px;
        .step-number {
          width: 32px;
          font-size: 48px;
        }
        .step-content {
          width: 100%;
          gap: 16px;

          .title {
            font-size: 24px;
          }

          .description {
            font-size: 16px;
          }
        }
      }
    }
  }
}
