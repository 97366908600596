.why-section {
  position: relative;
  margin: 96rem 0 48rem;
  padding: 0 40rem;

  @media (max-width: $screen-lg) {
    margin: 48px 0 32px;
    padding: 0 20px;
  }

  .title-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 56rem;
    color: $white-color;

    @media (max-width: $screen-lg) {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      margin-bottom: 32px;
    }

    h6 {
      opacity: .8;
      width: 25%;

      @media (max-width: $screen-lg) {
        width: 100%;
      }
    }
  }

  .items-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20rem;

    @media (max-width: $screen-lg) {
      flex-direction: column;
    }

    .item {
      flex: 1;
      padding: 32rem;
      overflow: hidden;
      position: relative;
      border-radius: 24rem;
      background: $card-gradient;

      @media (max-width: $screen-lg) {
        width: 100%;
        padding: 24px;
      }

      > div {
        position: relative;
        z-index: 1;
      }

      .bg-wrap {
        position: absolute;
        z-index: 0;
        top: 70rem;
        right: -5rem;

        @media (max-width: $screen-lg) {
          top: 30px;
        }

        img {
          height: 220rem;

          @media (max-width: $screen-lg) {
            height: 180px;
          }
        }
      }

      .icon-wrap {
        margin-bottom: 180rem;

        @media (max-width: $screen-lg) {
          margin-bottom: 80px;
        }

        img {
          width: 80rem;

          @media (max-width: $screen-lg) {
            width: 56px;
          }
        }
      }

      .text-wrap {
        color: $white-color;

        h4 {
          margin-bottom: 16rem;
        }

        p {
          opacity: .6;
        }
      }
    }
  }
}
