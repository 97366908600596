.trade-section {
  display: flex;
  padding: 0 40rem;
  position: relative;
  flex-wrap: wrap;
  z-index: 1;

  @media (max-width: $screen-lg) {
    padding: 0 20px;
  }

  .bg-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;

      @media (max-width: $screen-lg) {
        height: 100%;
        object-fit: cover;
        opacity: .1;
      }
    }
  }

  .content-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 140rem 0 200rem;
    width: 50%;

    @media (max-width: $screen-lg) {
      width: 100%;
      margin: 32px 0;
    }

    .text-wrap {
      color: $white-color;
      margin-bottom: 32rem;

      @media (max-width: $screen-lg) {
        margin-bottom: 24px;
      }

      h1 {
        margin-bottom: 32rem;

        @media (max-width: $screen-lg) {
          margin-bottom: 24px;
        }
      }

      h6 {
        max-width: 430rem;
        opacity: .8;
      }
    }

    .btn-wrap {
      position: relative;
      max-width: max-content;
    }
  }

  .phone-wrap {
    position: absolute;
    right: 40rem;
    bottom: 0;

    @media (max-width: $screen-lg) {
      position: relative;
      right: 0;
    }

    img {
      max-width: 650rem;

      @media (max-width: $screen-lg) {
        width: 100%;
      }
    }
  }
}
