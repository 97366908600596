header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 15;
  display: flex;
  align-items: center;
  height: 80rem;
  padding: 24rem 40rem;
  transition: $fast-transition;

  @media (max-width: $screen-lg) {
    padding: 15px 20px;
    justify-content: space-between;
  }

  &.scroll-header {
    padding: 20rem 40rem;
    backdrop-filter: blur(10rem);

    @media (max-width: $screen-lg) {
      padding: 15px 20px;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .8;
      background: $card-gradient;
    }
  }

  &.active-header {

    .nav-wrap {
      transform: none;
      overflow-y: auto;
      opacity: 1;
    }
  }

  .logo-wrap {
    z-index: 3;
    width: 225rem;

    img {
      width: 160rem;

      @media (max-width: $screen-lg) {
        width: 140px;
      }
    }
  }

  .toggle-wrap {
    padding: 16px 0;

    @media (max-width: $screen-lg) {
      position: relative;
      z-index: 3;
    }

    .btn-toggle {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      .text {
        color: $white-color;
      }

      .icons {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .icon {
          min-width: 39px;
          max-width: 39px;
          height: 2px;
          background-color: $white-color;
        }
      }
    }
  }

  .nav-wrap {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: $med-transition;

    @media (max-width: $screen-lg) {
      flex-direction: column;
      position: fixed;
      align-items: flex-start;
      height: var(--app-height);
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      padding: 100px 20px 40px;
      transform: translateY(-150%);
      background-color: #00020F;
      gap: 17px;
      opacity: 0;
    }

    .nav-links-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc((100% + 225rem) / 2 - 225rem + 50rem);

      @media (max-width: $screen-lg) {
        height: max-content;
      }

      ul {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 0;

        @media (max-width: $screen-lg) {
          align-items: flex-start;
          flex-direction: column;
          height: max-content;
          gap: 16px;
        }

        li {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0;
          margin: 0;
          position: relative;

          &.active, &:hover {
            background: linear-gradient(180deg, #00D1DD 0%, #5DFF00 100%), #5E6369;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }

        a {
          color: $white-color;
          text-decoration: none;
          width: max-content;
          cursor: pointer;
          font-weight: 500;

          p {
            @media (max-width: $screen-lg) {
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  .action-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $screen-lg) {
      width: 100%;
      flex-wrap: wrap;
      gap: 12px;
    }

    .socials-wrap {
      display: flex;
      align-items: center;
      gap: 8rem;
      margin-right: 36rem;

      a {
        display: flex;
        transition: $fast-transition;
        border-radius: 16rem;
        width: 56rem;
        height: 56rem;

        @media (max-width: $screen-lg) {
          width: 48px;
          height: 48px;
        }

        &:hover {
          background-color: rgba($white-color, .1);
        }
      }

      img {
        width: 56rem;

        @media (max-width: $screen-lg) {
          width: 48px;
        }
      }
    }

    .btn-wrap {

      a {
        font: 500 18rem/1.2 $headline-font;
        letter-spacing: calc(-18rem * .02);
        display: flex;
        color: $white-color;
        padding: 15rem 42rem;
        text-decoration: none;
        border-radius: 90rem;
        border: 2rem solid rgba($white-color, .4);
        transition: $fast-transition;

        @media (max-width: $screen-lg) {
          font-size: 16px;
          padding: 12px 36px;
        }

        &:hover {
          background-color: rgba($white-color, .1);
        }
      }
    }
  }
}
